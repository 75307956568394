<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        Subvendo Credits
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
    </template>
    <template slot="content">
      <v-container>
        <v-tabs>
          <v-tab><strong>My Subvendo Credits</strong></v-tab>
          <v-tab><strong>Transfer Logs</strong></v-tab>
          <v-tab-item>
            <my-subvendo-credits></my-subvendo-credits>
          </v-tab-item>
          <v-tab-item>
            <subvendo-transfer-logs></subvendo-transfer-logs>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </template>
  </dashboard-layout>
</template>

<script>
  import MySubvendoCredits from '@/views/dashboard/subvendo-credits/MySubvendoCredits'
  import SubvendoTransferLogs from '@/views/dashboard/subvendo-credits/SubvendoTransferLogs'

  export default {
    name: 'SubvendoCredits',
    components: {
      MySubvendoCredits,
      SubvendoTransferLogs
    }
  }
</script>