<template>
  <div style="padding: 20px 10px">
    <div v-if="transferLogsLoading" style="display: flex; align-items: center; justify-content: center; height: 80vh;">
      <loading-circular></loading-circular>
    </div>
    <template v-if="!transferLogsLoading">
      <v-card>
        <div>
          <v-alert type="warning" v-if="!subvendoTransferLogs.length">No subvendo transfer logs</v-alert>
          <div class="mb-5 mt-5" v-if="subvendoTransferLogs.length">
            <v-data-table :headers="subvendoLogsHeaders" :items="subvendoTransferLogs" class="elevation-1 table" :items-per-page="20" >
              <template v-slot:item.created_at="{item}">
                <span>{{formatDate(item.created_at)}}</span>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>

  import moment from 'moment'
  import { mapGetters, mapActions } from 'vuex'  
  import LoadingCircular from '@/components/LoadingCircular.vue'
  import catch_http_error from '@/services/http/catch_http_error'
  export default {
    name: 'SubvendoTransferLogs',
    components: {
      LoadingCircular
    },
    data() {
      return {
        subvendoLogsHeaders: [
          {
            text: 'From',
            align: 'start',
            sortable: false,
            value: 'from_user'   
          },
          {
            text: 'To',
            align: 'start',
            sortable: false,
            value: 'to_user'   
          },
          {
            text: 'Number of Credits',
            align: 'start',
            sortable: false,
            value: 'subvendo_credits_count'   
          },
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'created_at'
          }
        ]
      }
    },
    created() {
      this.load()
    },
    methods: {
      async load() {
        try {
          await this.fetchSubvendoTransferLogs()
        } catch (e) { catch_http_error(e) }
      },
      formatDate (date) {
        return moment(date).format('MMM DD, YYYY @ hh:mm:ss a')
      },
      ...mapActions(['fetchSubvendoTransferLogs'])
    },
    computed: {
      ...mapGetters(['subvendoTransferLogs', 'transferLogsLoading'])
    }
  }
</script>