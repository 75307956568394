<template>
  <div style="padding: 20px 10px">
    <div v-if="creditLoading" style="display: flex; align-items: center; justify-content: center; height: 80vh;">
      <loading-circular></loading-circular>
    </div>
    <template v-if="!creditLoading">
      <div style="display: flex; justify-content: space-between;">
        <v-spacer></v-spacer>
        <transfer-subvendo-credits :available_credits="availableSubvendoCredits"></transfer-subvendo-credits>
      </div>
      <v-card style="padding: 15px;">
        <div>
          <h2 class="font-weight-regular">
            Available Subvendo Credits: <span style="color: #b300b3; font-weight: 800">
              {{availableSubvendoCredits.length}}
            </span>
          </h2> 
          <v-alert class="mt-5" type="warning" v-if="!availableSubvendoCredits.length">No available subvendo credits</v-alert>
        </div>
      </v-card>
      <v-card style="padding: 15px;" class="mt-5">
        <div>
          <h2 class="font-weight-regular">
            Used Subvendo Credits: <span style="color: #b300b3; font-weight: 800">{{usedSubvendoCredits.length}}</span>
          </h2>
          <v-alert class="mt-5" type="warning" v-if="!usedSubvendoCredits.length">No used subvendo credits</v-alert>

          <div class="mb-5 mt-5" v-if="usedSubvendoCredits.length">
            <v-data-table :headers="useSubvendoHeaders" :items="usedSubvendoCredits"
              :items-per-page="20"
              class="elevation-1 table"
             >
             <template v-slot:item="row">
               <tr>
                 <td>{{row.item.machine}}</td>
                 <td>{{row.item.subvendo_name}}</td>
                 <td>{{formatDate(row.item.activated_at)}}</td>
                 <td>
                   <v-btn color="red" small style="color: white" @click="revoke(row.item.id)">
                     Revoke
                   </v-btn>
                 </td>
               </tr>

             </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import LoadingCircular from '@/components/LoadingCircular.vue'
  import TransferSubvendoCredits from '@/views/dashboard/subvendo-credits/TransferSubvendoCredits.vue'
  import catch_http_error from '@/services/http/catch_http_error'
  import { revokeSubvendoCredits } from '@/services/http/subvendo_credits'
  import moment from 'moment'

  export default {
    name: 'MySubvendoCredits',
    components: {
      LoadingCircular,
      TransferSubvendoCredits
    },
    data () {
      return {
        useSubvendoHeaders: [
          {
            text: 'Device',
            align: 'start',
            sortable: false,
          },
          {
            text: 'Name',
            sortable: false,
          },
         {
            text: 'Activated At',
            sortable: false,
          },
          {
            text: 'Option'
          }
        ]
      }
    },
    created() { 
      this.load()
    },
    methods: {
      async load () {
        try {
          await this.fetchSubvendoCredits()
        } catch (e) {
          catch_http_error(e)
        }
      },
      formatDate (date) {
        return moment(date).format('MMM DD, YYYY @ hh:mm:ss a')
      },
      async revoke(id) {
        const yn = await this.$confirm('Are you sure you want to revoke ?', {title: 'Revoke Subvendo Credit'})
        if (yn) {
          await revokeSubvendoCredits(id)
          this.load()
        }
      },
      ...mapActions(['fetchSubvendoCredits'])
    },
    computed: {
      ...mapGetters(['availableSubvendoCredits', 'usedSubvendoCredits', 'creditLoading'])
    }

  }
</script>