<template>
  <v-dialog v-model="dialog" max-width="350px">
    <template v-slot:activator="{on, attrs}">
      <a class="v-btn ml-5" href="https://www.adopisoft.com/activation#subvendo-credit">Buy Credits</a>
      <v-btn :disabled="Boolean(!available_credits.length)" class="mt-3 mb-3" color="primary" title="Transfer Credits" v-bind="attrs" v-on="on">
        Transfer Credits
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Transfer Subvendo Credits
      </v-card-title>
      <v-divider></v-divider>
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-alert v-if="error" type="error">{{error}}</v-alert>
            <v-form ref="form" lazy-validation v-model="isFormValid">
              <v-text-field type="email" label="Recipient Email: " v-model="toEmail" :rules="emailRules" required ></v-text-field>

              <v-text-field type="number" label="No. of Credits: " v-model="creditsCount" :rules="creditRules" required min="1"></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :disabled="!isFormValid || submitting" color="primary" @click="transferCredits" elevation="2">
          <span>{{submitting ? 'Transfering ...' : 'Transfer'}}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
  import { transferSubvendoCredits } from '@/services/http/subvendo_credits'
  import { mapActions } from 'vuex'

  export default {
    name: 'TransferSubvendoCredits',
    components: {},
    props: {
      available_credits: null
    },
    data: () => ({
      toEmail: null,
      creditsCount: null,
      dialog: false,
      submitting: false,
      isFormValid: false,
      error: null,
      //rules
      emailRules: [
        v => !!v || 'E-mail field is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      creditRules: [
        v => !!v || 'Subvendo credits field is required',
        v => !!v  && v > 0 || 'Credits must be greater than to zero'
        ]
    }),
    methods: {
      cancel() {
        this.dialog = false
        this.creditsCount = null
        this.toEmail = null
        this.isFormValid = false
        this.submitting = false
      },
      async transferCredits() {
        try {
          this.isFormValid = this.$refs.form.validate()
          if (!this.isFormValid) return
          this.submitting = true
          await transferSubvendoCredits({to_email: this.toEmail, subvendo_credits_count: this.creditsCount})
          await this.fetchSubvendoCredits()
          await this.fetchSubvendoTransferLogs()
          this.dialog = false

        } catch (e) {
          if (e.response) {
            this.error = e.response.data.message
          }
        } finally{
          this.submitting = false
        }
      },
      ...mapActions(['fetchSubvendoCredits', 'fetchSubvendoTransferLogs'])
    },
    watch: {
      toEmail (v) {
       if (v) {
        this.error = null  
       }
     },
      creditsCount (v) { 
        if (v) {
          this.error = null
        }
      }
    }
  }
</script>